import React, { useState, useEffect } from "react";
import axios from "axios";

import ReCAPTCHA from "react-google-recaptcha";
import Button from "../components/Button";
import DropDown from "../components/DropDown";
import { useRouter } from "next/router";
import { useAuth } from "../src/AuthContext";
import { useSocketStatus } from "../src/SocketStatusContext";
import Image from "next/image";
import { Checkbox, Flex, Input, Select, Stack, Text } from "@chakra-ui/react";
import { fetchCompanies } from "../utils/axiosHelper";
import { useMutation } from "@apollo/client";
import { LOGIN_MUTATION } from "../graphql/mutations/login";
import { CLIENT_LOGIN_MUTATION } from "../graphql/mutations/clientLogin";
import { GET_CLIENT } from "../graphql/queries/getClient";
import { client } from "../lib/apollo";
import FullPageLoader from "../components/FullPageLoader";
import OverlayLoader from "../components/OverlayLoader.js";

const LeftPanel = ({
  baseUrl,
  captchaKey,
  basicUser,
  basicPwdOne,
  basicPwdTwo,
}) => {
  const router = useRouter();
  const authCtx = useAuth();
  const {
    user: { user },
    isAuthenticated,
  } = useAuth();
  const [isLoaded, setIsLoaded] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [type, setType] = useState("");
  const [checked, setChecked] = useState(false);
  const [validEmail, setValidEmail] = useState(true);
  const [validPassword, setValidPassword] = useState(true);
  const [validType, setValidType] = useState(true);
  const [validCheck, setValidChecked] = useState(null);

  const [validateCaptcha, setValidateCaptcha] = useState();
  const [validCaptcha, setValidCaptcha] = useState(true);

  const [isAdminLogin, setIsAdminLogin] = useState(false);
  const [savedResponse, setSavedResponse] = useState({});
  const [validCompany, setValidCompany] = useState(true);
  const [company, setCompany] = useState("");
  const [companies, setCompanies] = useState([]);

  const searchParams = new URLSearchParams(
    typeof window !== "undefined" ? location.search : ""
  );

  const trips = searchParams.get("trips");

  useEffect(() => {
    // If the user is already authenticated, redirect them to the specified page
    if (user && user?.role === "dispatcher") {
      router.push("dispatchers");
    } else if (user && user?.type === "business" && user?.clientId) {
      client
        .query({ query: GET_CLIENT, variables: { clientId: user.clientId } })
        .then((result) => {
          if (result.data.getClient && result?.data?.getClient?.business_id) {
            // This is Business login

            router.push(
              `/vrtB?bUsIid=${btoa(
                result?.data?.getClient?.business_id
              )}&aCCeshAs=${btoa(1)}&trips=${trips}`
            );
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } else if (isAuthenticated) {
      router.push("home");
    } else if (!isAuthenticated) {
      setIsLoaded(true);
    }
  }, [user, router, trips, isAuthenticated]);

  const [login, { loading, error }] = useMutation(LOGIN_MUTATION, {
    onCompleted: (data) => {
      console.log("data after success in login ", data);
      if (type === "admin" && data.employeeLogin.role === "admin") {
        // This will be admin login
        setSavedResponse({
          user: {
            type: type,
            role: data.employeeLogin.role,
            id: data.employeeLogin.id,
          },
          token: data.employeeLogin.token,
        });
        setIsAdminLogin(true);
      } else if (type === "admin" && data.employeeLogin.role === "company") {
        // This will be company login
        authCtx.login({
          user: {
            id: data.employeeLogin.id,
            role: data.employeeLogin.role,
            type: "company",
          },
          token: data.employeeLogin.token,
        });
      } else {
        // This is dispatcher login
        authCtx.login({
          user: {
            id: data.employeeLogin.id,
            role: data.employeeLogin.role,
            type: "company",
          },
          token: data.employeeLogin.token,
        });
      }
    },
  });

  const [businessLogin, { loading: businessLoading, error: businessError }] =
    useMutation(CLIENT_LOGIN_MUTATION, {
      onCompleted: (data) => {
        console.log("data after success in login ", data);
        if (
          type === "business" &&
          data?.clientLogin?.token &&
          data?.clientLogin?.type === "business"
        ) {
          authCtx.login({
            user: {
              clientId: data?.clientLogin?.id,
              type: "business",
            },
            token: data?.clientLogin?.token,
          });
        } else {
          // This is company login
          authCtx.login({
            user: {
              id: data.employeeLogin.id,
              role: data.employeeLogin.role,
              type: "company",
            },
            token: data.employeeLogin.token,
          });
        }
      },
    });

  useEffect(() => {
    if (savedResponse?.token) {
      const headers = {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${savedResponse?.token}`,
      };

      fetchCompanies(headers).then((data) => {
        setCompanies(data.data);
      });
    }
  }, [savedResponse.token]);

  const handleSubmit = async () => {
    console.log("Handle submit", email, password);
    // Check email validation
    //
    // eslint-disable-next-line no-useless-escape
    const emailValidation =
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    const emailTest = emailValidation.test(String(email).toLowerCase());

    // Check password validation must contain
    //
    // At least one upper case English letter, (?=.*? [A - Z])
    // At least one lower case English letter, (?=.*? [a - z])
    // At least one digit, (?=.*? [0 - 9])
    // At least one special character, (?=.*? [#?!@$%^&* -])
    // Minimum eight in length.{ 8,} (with the anchors)
    // const passwordValidation = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/i;
    // const passwordTest = passwordValidation.test(String(password));

    // Check if the following arguments are true otherwise set states to alert the user
    if (!checked) {
      setValidChecked(false);
    }
    if (!emailTest) {
      setValidEmail(false);
    }
    if (!password) {
      setValidPassword(false);
    }
    if (!type) {
      setValidType(false);
    }
    if (!validateCaptcha) {
      setValidCaptcha(false);
      return;
    }
    // If all the above are true then we proceed to next screen
    if (emailTest && validCheck && type) {
      try {
        if (type === "business") {
          await businessLogin({ variables: { email, password } });
        } else {
          await login({ variables: { email, password, type } });
        }
      } catch (error) {
        console.log("error ", error);
      }
    }
  };

  const validateRecaptcha = (token) => {
    const stringToken = `${basicUser}:${basicPwdOne + basicPwdTwo}`;
    const local_token = Buffer.from(stringToken).toString("base64");

    const headers = {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Basic ${local_token}`,
    };

    axios
      .post(
        `${baseUrl}/client/validateRecaptcha`,
        {
          token,
        },
        {
          headers: headers,
        }
      )
      .then(({ data }) => {
        setValidateCaptcha(data.success);
      })
      .catch((error) => console.log(error));
  };

  const confirmCompanySelection = () => {
    authCtx.login({
      user: {
        ...savedResponse.user,
        type: "admin",
        company_id: parseInt(company),
      },
      token: savedResponse.token,
    });
    router.push("/home");
  };

  const termsColor = validCheck === false ? "#ff0000" : "#000000";

  return (
    <>
      {!isLoaded ? (
        <OverlayLoader />
      ) : (
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          {!isAdminLogin ? (
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              width="400px"
            >
              <Image
                width={0}
                height={0}
                sizes="100vw"
                src={"/assets/logo_color.png"}
                alt="logo"
                priority
                style={{ width: "200px" }}
              />
              <Stack width="100%" spacing={3} p={10} direction="column">
                <Input
                  name="email"
                  type="email"
                  shadow={"xs"}
                  placeholder="Email"
                  borderColor={"gray.300"}
                  autoComplete="off"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setValidEmail(true);
                  }}
                  focusBorderColor="transparent"
                  isRequired
                />
                {!validEmail && (
                  <span
                    style={{
                      fontFamily: "Lato, sans-serif",
                      fontSize: 12,
                      marginBottom: 10,
                      color: "red",
                    }}
                  >
                    You must enter a valid email address
                  </span>
                )}
                <Input
                  name="password"
                  type="password"
                  shadow={"xs"}
                  borderColor={"gray.300"}
                  placeholder="Password"
                  autoComplete="off"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setValidPassword(true);
                  }}
                  focusBorderColor="transparent"
                  isRequired
                />
                {!validPassword && (
                  <span
                    style={{
                      fontFamily: "Lato, sans-serif",
                      fontSize: 12,
                      marginBottom: 10,
                      color: "red",
                    }}
                  >
                    You must enter your password
                  </span>
                )}

                <Select
                  placeholder="Select User Type"
                  onChange={(e) => {
                    if (e.target.value) {
                      setType(e.target.value);
                      setValidType(true);
                    } else {
                      setValidType(false);
                    }
                  }}
                >
                  <option value="employee">Dispatcher</option>
                  <option value="admin">Admin</option>
                  <option value="business">Business</option>
                </Select>

                {!validType && (
                  <span
                    style={{
                      fontFamily: "Lato, sans-serif",
                      fontSize: 12,
                      marginBottom: 10,
                      color: "red",
                    }}
                  >
                    Please select User Type
                  </span>
                )}
              </Stack>
              <ReCAPTCHA
                // value={values.captcha}
                sitekey={captchaKey}
                onChange={(value) => {
                  validateRecaptcha(value);
                }}
              />
              {!validCaptcha && (
                <span
                  style={{
                    fontFamily: "Lato, sans-serif",
                    fontSize: 12,
                    marginBottom: 10,
                    color: "red",
                  }}
                >
                  Captcha is required.
                </span>
              )}
              {((type !== "business" && error) ||
                (type === "business" && businessError)) && (
                <span
                  style={{
                    fontFamily: "Lato, sans-serif",
                    fontSize: 14,
                    marginBottom: 10,
                    color: "red",
                  }}
                >
                  {type !== "business" && error && error.message
                    ? error.message
                    : type === "business" &&
                      businessError &&
                      businessError.message
                    ? businessError.message
                    : "It looks like the email or password are incorrect."}
                </span>
              )}
              <Flex p={4} justifyContent={"center"} alignItems={"center"}>
                <Checkbox
                  fontSize={10}
                  name="termsAndConditions"
                  onChange={(e) => {
                    setChecked(e.target.checked);
                    setValidChecked(!validCheck);
                  }}
                  alignItems={"center"}
                  checked={checked}
                  borderColor={"#433969"}
                >
                  <Text fontSize={"sm"} color={termsColor}>
                    I have read and accept EyeSafe's Terms & Conditions
                  </Text>
                </Checkbox>
              </Flex>
              <Button
                title={loading || businessLoading ? "Logging in..." : "Login"}
                onClick={handleSubmit}
                disabled={loading || businessLoading}
                customStyle={{
                  width: 260,
                  height: 48,
                  backgroundColor: "#473E67",
                }}
              />
              <div className="Login-disclaimer">
                By completing this form you agree to the processing of your data
                in accordance to EyeSafe's Privacy Policy. For more information
                please visit www.eyesafe.london
              </div>
            </Flex>
          ) : (
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              width="400px"
              gap={3}
            >
              <Image
                width={0}
                height={0}
                sizes="100vw"
                src={"/assets/logo_color.png"}
                alt="logo"
                priority
                style={{ width: "200px" }}
              />
              <span className="New-title">Select Company</span>
              <DropDown
                isValid={validCompany}
                placeholder="Select Company"
                options={companies}
                selectedOption={company}
                height={"auto"}
                onSelect={(e) => {
                  if (e) {
                    setCompany(e);
                    setValidCompany(true);
                  } else {
                    setCompany(e);
                    setValidCompany(false);
                  }
                }}
              />
              <Button
                title="Confirm"
                disabled={!company}
                onClick={confirmCompanySelection}
                customStyle={{
                  width: 260,
                  height: 48,
                  backgroundColor: "#473E67",
                }}
              />
            </Flex>
          )}
        </Flex>
      )}
    </>
  );
};

export default LeftPanel;

export async function getServerSideProps() {
  return {
    props: {
      captchaKey: process.env.NEXT_PUBLIC_APP_GOOGLE_CAPTCHA_KEY,
      baseUrl: process.env.NEXT_PUBLIC_APP_API_ENDPOINT,
      basicUser: "$2b$10$" + process.env.NEXT_VERT_BASIC_USER,
      basicPwdOne: "$2b$10$" + process.env.NEXT_VERT_BASIC_PWD_PART_ONE,
      basicPwdTwo: process.env.NEXT_VERT_BASIC_PWD_PART_TWO,
    },
  };
}
